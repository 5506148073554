<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="edit_page()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-card>
          <v-card-title>Edycja kategorii</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>

                <v-text-field
                    v-model="data.title"
                    label="Nazwa"
                    color="primary"
                  ></v-text-field>

                  <vue-editor v-model="data.description"></vue-editor>

                  <v-file-input
                    v-model="files"
                    truncate-length="15"
                    chips
                    multiple
                    label="Załącz pliki"
                  ></v-file-input>

                  <v-switch
                    v-model="data.published"
                    label="Czy opublikować"
                  ></v-switch>
               
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card v-if="data.files.length" class="card_section">
          <v-card-title>Usuwanie plików</v-card-title>
          <v-card-text>
            <v-btn
              style="margin: 8px"
              color="secondary_dark"
              dark
              depressed
              v-for="item in data.files"
              :key="item.id"
              @click="delete_file(item.id)"
            >{{item.name}}</v-btn>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    
    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć stronę?"
      @close="dialog = false;"
      @confirm="delete_page()"
    />

    <confirm_dialog
      v-if="dialog_file"
      text="Czy na pewno chcesz usuąć plik?"
      @close="dialog_file = false;"
      @confirm="delete_file_confirmed()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    category: 0,
    dialog: false,
    dialog_file: false,
    delete_file_id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Kategoria',
        disabled: false,
        to: '/baza-wiedzy/kategoria',
      },
      {
        text: 'Szczegóły',
        disabled: false,
        to: '/baza-wiedzy/zobacz',
      },
      {
        text: 'Edytuj',
        disabled: true,
        to: '/edit',
      }
    ],

    user_data: {
      category: 1,
      email: 'Rzeszów',
      name: 'Andrzej Maślak z ZOO',
      numebr: '1233',
    },
    
    search: '',
    data: {files: []},
    files: null
  }),
  methods: {
    // #TODO nie działa wrzucanie plików, zimmny ma cos naprawic, podpiete jest prawidlowo
    edit_page() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("title", this.data.title);
      formData.append("description", this.data.description);
      if(this.data.published) formData.append("published", 1);
      else formData.append("published", 0);

      this.$axios({url: this.$store.state.api +'/faq/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {

          // Jeżeli są pliki do wrzucenia
          if(this.files){
            let formDataNew = new FormData();
            formDataNew.append("type", 'faq');
            formDataNew.append("root", this.id);
            for(let file in this.files){
              formDataNew.append("files["+file+"][file]", this.files[file]);
            }
            this.$axios({url: this.$store.state.api +'/file', data: formDataNew, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
            .then(resp => {
              this.$store.commit("snackbar", {
                text: "Wprowadzono zmiany",
                color: "primary",
                btn_color: "white"
              });
              this.$router.push('/baza-wiedzy/zobacz/'+this.id);
              console.log(resp.data);
            })
            .catch(err => {
              this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
            })
            .then(()=> {
              this.$store.commit('loader_off');
            })
          }
          else {
            this.$router.push('/baza-wiedzy/zobacz/'+this.id);
            this.$store.commit("snackbar", {
              text: "Wprowadzono zmiany",
              color: "primary",
              btn_color: "white"
            });
          console.log(resp.data);
          }

        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
        })
    },
    // Otwieranie modala - czy usunac zalacznik 
    delete_file(n){
      this.delete_file_id = n;
      this.dialog_file = true;
    },
    delete_file_confirmed(){
      this.dialog_file = false;
      this.$store.commit('loader');
      console.log('kur')

      this.$axios({url: this.$store.state.api +'/file/'+this.delete_file_id, data: {}, method: 'DELETE' })
      .then(resp => {
        // this.data = resp.data;
        console.log(resp.data);
        this.$store.commit("snackbar", {
          text: "Usunięto plik",
          color: "primary",
          btn_color: "white"
        });

        // Usuwanie pliku z listy (aby nie pobierać ponownie zmian)
        for(const file in this.data.files){
          if(this.data.files[file].id == this.delete_file_id) this.data.files.splice(file,1)
        }

        // this.$axios({url: this.$store.state.api +'/faq/'+this.id, data: {}, method: 'GET' })
        //   .then(resp => {
        //     this.breadcrumb[2].to = '/baza-wiedzy/kategoria/'+resp.data.categories_id;
        //     this.breadcrumb[3].to = '/baza-wiedzy/zobacz/'+resp.data.id;
        //     this.data.files = resp.data.files;
        //     console.log(resp.data);
        //   })
        //   .catch(err => {
        //     this.$store.commit('snackbar', {text: err.message, color: 'red', btn_color: 'black'})
        //   })
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then(()=> {
        this.$store.commit('loader_off');
      })
    },
    delete_page(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/faq/'+this.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$router.push('/baza-wiedzy/kategoria/'+this.data.categories_id);
          this.$store.commit("snackbar", {
            text: "Usunięto stronę",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then(()=> {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.id = this.$route.params.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/faq/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.breadcrumb[2].to = '/baza-wiedzy/kategoria/'+resp.data.categories_id;
        this.breadcrumb[3].to = '/baza-wiedzy/zobacz/'+resp.data.id;
        this.data = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then(()=> {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
</style>